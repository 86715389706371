<template>
  <div class = "index">

    <!-- index -->
    <ul key='text' v-if='Object.keys(this.indexData).length > 0 && !this.imageView' v-on:mouseenter='handleMouseEnter' v-on:mouseleave='handleMouseLeave' :class='this.returnClass'>
      <li class = 'table-row' v-for='project in this.filteredIndex' :key='project.key' v-on:mouseover='handleMouseOver($event, project)'>
        <a v-on:click='handleLinkClick($event, project)' :class='returnLinkClass(project.slug)' :to='"/projects/" + project.slug'>
          <div class = 'title'>
            <div id='year' v-if='!mobile'>
              <!-- would be ideal not to duplicate key here -->
              <span v-for='i in returnKernedYear(project.year)' :key='i' :class='returnLetterClass(i)'>{{ i }}</span>
            </div>
            <div id='title'>{{ project.title }}</div>
          </div>
          <div class = 'tags'>
            <div class = 'mobile-year' v-if='mobile'>{{ project.year }}</div>
            <div class = 'mobile-dot' v-if='mobile'></div>
            <div class = 'mobile-tags'>{{ project.tags }}</div>
          </div>
        </a>
      </li>
    </ul>
    
    <ul key='image' v-else-if='Object.keys(this.indexData).length > 0 && this.imageView' :class='this.returnClass'>
      <li class = 'table-block' v-for='project in this.filteredIndex' :key='project.key'>
        <div v-if='$route.name === "Index"' ref='waypoint' class='table-block_waypoint' :data-title='project.title' :data-year='project.year' :id='returnWaypointId(project.title)'></div>
        <router-link :to="'/projects/' + project.slug">
          <!-- todo: add srcset -->
          <img :src='project.thumbnail.thumbs.lg' />
        </router-link>
      </li>
    </ul>


     <transition name = 'slides-fade'
      v-on:before-enter="beforeEnter"
      v-on:after-enter="afterEnter"
      v-on:before-leave="beforeLeave"
      v-on:after-leave="afterLeave"
    >
        <!-- keep alive caches the component, prevent reload of images etc. -->
        <keep-alive>
          <Slides ref='slider' :key='this.returnSlidesKey' v-if='Object.keys(this.indexData).length > 0 && !this.touch && !this.mobile' :touch='this.touch' :mobile='this.mobile' :windowWidth='this.windowWidth' :variableClipWidth='this.variableClipWidth' :clipWidth='this.clipWidth' :slideCount='this.slideCount' :slideTotal='this.slideTotal' :showSlides='this.showSlides' :projectInfo='this.projectInfo' :files='this.returnSlideFiles' :cachedImages='this.cachedImages' />
        </keep-alive>
     </transition>

    <!-- view for nested project route -->
    <!-- transition only for touch/mobile devices -->
    <transition v-if='this.touch || this.mobile' :name='returnNestedTransition'>
      <router-view :key='this.$route.path' :cursorPos='this.cursorPos' :mobile='this.mobile' :touch='this.touch' :projectInfo='this.projectInfo' v-if='Object.keys(this.indexData).length > 0' />
    </transition>
  
    <router-view v-if='Object.keys(this.indexData).length > 0 && !this.touch && !this.mobile' :key='this.$route.path' :cursorPos='this.cursorPos' :mobile='this.mobile' :touch='this.touch' :projectInfo='this.projectInfo' />

    <Footer v-if='this.touch || this.mobile' :imageView='this.imageView' :title='this.title' :filter='this.filter' :projectInfo='this.projectInfo' :slideCount='this.slideCount' :slideTotal='this.slideTotal' />
  </div>
</template>

<script>

import Slides from '../components/Slides.vue'
import Footer from '../components/Footer.vue'

import api from '../utils/api'
import debounce from 'lodash/debounce'


export default {
  name: 'Index',
  props: {
    mobile: Boolean,
    touch: Boolean,
    indexData: Array,
    indexProject: Object,
    windowWidth: Number,
    title: String,
    filter: String,
    projectInfo: Boolean,
    showSlides: Boolean,
    slideTotal: Number,
    slideCount: Number,
    cachedImages: Array,
    clipWidth: Number,
    cursorPos: Object,
    imageView: Boolean,
  },
  components: {
    Slides,
    Footer
  },
  data() {
    return {
      variableClipWidth: false,
      imageObserverBound: false, 
      scrollObserverBound: false,
      imageIntersectionOptions: {
        // keep eye on this 
        root: document.querySelector('.slide-wrap'),
        rootMargin: '0px 0px 0px 0px',
        threshold: 0
      },
      scrollIntersectionOptions: {
        root: null,
        rootMargin: '0px 0px -60% 0px',
        threshold: 0
      }
    }
  },
  updated: debounce(function () {
    this.$nextTick(() => {
      this.bindScrollObserver()
      // this.bindImageObserver()
    })
  }, 250),
  mounted() {
    // seems to work: keep an eye on this + test in other browsers
    this.bindScrollObserver()
    this.bindImageObserver()
  },
  beforeDestroy() {
    this.$store.dispatch('unsetTitle')
  },
  watch: {
    // 'indexProject': function() {
    //   this.bindImageObserver()
    // },
    '$route.name': function() {
      if (this.$route.name === 'Project') {
        const self = this 
        // reset filter when project is selected 
        // timeout is to wait for clip-path transition of <Slides />
        setTimeout(() => {
          self.$store.dispatch('resetFilter')
        }, 1000)
      }
      else if (this.$route.name === 'Index') {
        this.scrollObserverBound = false 
        const self = this 
        setTimeout(() => {
          self.bindScrollObserver()
          self.bindImageObserver()
        }, 1)
      }
    },
    'imageView': function(newVal, oldVal) {
      if (!newVal) {
        // may want to put this in a fn
        this.scrollObserverBound = false
      }
      else {
        const self = this 
        this.scrollObserverBound = false 
        setTimeout(() => {
          self.bindScrollObserver()
        }, 1)
      }
    }
  },
  computed: {
    returnClass: function() {
      if (this.touch || this.mobile) {
        return this.textCursor ? 'table touch text-cursor' + ' ' + this.$route.name.toLowerCase() : 'table touch' + ' ' + this.$route.name.toLowerCase()
      }
      else {
        return this.textCursor ? 'table text-cursor' + ' ' + this.$route.name.toLowerCase() : 'table' + ' ' + this.$route.name.toLowerCase()
      }
    },
    filteredIndex: function() {
      const self = this 
      if (self.filter === 'All') {
        return this.indexData 
      }
      else {
        return this.indexData.filter((entry, key) => {
          return entry.type.includes(self.filter)
        })
      }
    },
    returnSlidesKey: function() {
      return Object.keys(this.indexProject).length === 0 ? '' : this.indexProject.slug
    },
    returnSlideFiles: function() {
      return Object.keys(this.indexProject).length === 0 ? [] : this.indexProject.files.sort((a, b) => a.index - b.index)
    },
    returnNestedTransition: function() {
      return this.$route.path === '/' ? 'slide-down' : 'slide-up'
    }
  },
  methods: {
    // may be a good idea to rename transition callbacks 
    beforeEnter: function() {
      // this.bindImageObserver()
      // ...
    },
    afterEnter: function() {
      const self = this 
      setTimeout(() => {
        self.bindImageObserver()
      }, 250)
      // ...
    },
    beforeLeave: function() {
      // ...
      // this.bindImageObserver()
    },
    // ...
    afterLeave: function() {
    },
    returnLetterClass: function(n) {
      return 'n-' + n
    },
    returnKernedYear: function(year) {
      let arr = []
      for (let i = 0; i < year.length; i++) {
        arr.push(year[i])
      }
      return arr 
    },
    returnWaypointId: function(str) {
      return str.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'')
    },
    bindImageObserver: function() {
      console.log('bind image observer')
      const self = this 
      const observer = new IntersectionObserver(this.imageObserverCallback, this.intersectionOptions)
      // would be ideal to do this with $refs instead
      let target = document.querySelectorAll('.slide-wrap-inner_slide')
      if (target) {
        target.forEach((i) => {
          if (i) {
            observer.observe(i)
          }
        })
      }
    },
    imageObserverCallback: function(entries, observer) {
      const self = this 
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // entry.target.classList.remove('loading')
          // entry.target.classList.add('loaded')
          // console.log('entry', entry.target)
          const dataType = entry.target.getAttribute('data-type')
          if (dataType === 'image') {
            let lazyImage = entry.target.querySelector('img')
            lazyImage.src = lazyImage.getAttribute('data-src')
          }
          else if (dataType === 'video') {
            // todo: check if video is not playing already 
            let video = entry.target.querySelector('video')
            video.play()
          }
        }
      })
    },
    bindScrollObserver: function() {
      const self = this 
      const observer = new IntersectionObserver(this.scrollObserverCallback, this.scrollIntersectionOptions)
      let target = this.$refs.waypoint
      if (target) {
        if (this.mobile && !this.scrollObserverBound || this.touch && !this.scrollObserverBound) {
          console.log('now bind observer')
          target.forEach((i) => {
            if (i) {
              observer.observe(i)
            }
          })
          this.scrollObserverBound = true
        }
      }
    },
    scrollObserverCallback: function(entries, observer) {
      const self = this 
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const id = entry.target.id
          const title = entry.target.getAttribute('data-title')
          const year = entry.target.getAttribute('data-year')
          const titleWithYear = title + ',' + ' ' + year
          this.$store.dispatch('setTitle', titleWithYear)
        }
      })
    },
    handleLinkClick: function(event, proj) {
      event.preventDefault()
      this.$router.push('/projects/' + proj.slug)
    },
    returnLinkClass: function(slug) {
      if (!this.touch) {
        return slug === this.indexProject.slug ? 'table-row_link active' : 'table-row_link'
      }
      else {
        return 'table-row_link'
      }
    },
    handleMouseEnter: function() {
      this.variableClipWidth = true
    },
    handleMouseLeave: function() {
      this.variableClipWidth = false
    },
    // debounce mouse over event to prevent <Slides /> component
    // remounting (+ therefore reloading imgs) too many times
    handleMouseOver: debounce(function (event, proj) {
      console.log('handle mouse over')
      if (event.pageX <= (window.innerWidth / 2)) {
        this.$store.dispatch('setIndexProject', proj)
      }
    }, 30),
  }
}

</script>

<style lang="scss">

@import "../styles/_helpers.scss";

// no scoped here
.table {
  list-style: none;
  padding: 0;
  margin: 0;
  grid-column-start: 1;
  grid-column-end: 13;
  display: flex;
  flex-flow: column;
  overflow-x: hidden;
  &.index {
    transition: opacity .125s linear;
    opacity: 1;
  }
  &.project {
    transition: opacity 0s linear 1s, visibility 0s linear 1s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    &.touch {
      opacity: unset;
      visibility: unset;  
    }
    @include bp-xs {
      opacity: unset;
      visibility: unset;
    }
  }
  & * > a {
    // cursor: pointer !important;
    // cursor: none !important;
  }
  &-row {
    display: flex;
    &_link {
      align-items: center;
      width: 100%; 
      @include grid;
      padding: 2.2rem $margin 2.2rem $margin;
      border-bottom: solid black 1px;
      @media (hover: hover) {
        // look out for lag on .active class (:hover may be faster)
        &.active {
          background: black;
          color: white;
          & .title {
            @include italic;
          } 
          & .tags {
            @include italic;
          }
          & .year {
            @include italic;
          }
        }
      }
      @include bp-xs {
        padding: 0;
      }
    }
  }
  // image view 
  &-block {
    position: relative;
    font-size: 0;
    &_waypoint {
      height: 10px;
      top: 0;
      position: absolute;
      width: 100%;
      z-index: 101;
      pointer-events: none;
      opacity: 0;
    }
    & a {
      display: flex;
      height: auto;
      width: 100%;
      & img {
        width: 100%;
        // max-height: calc((100vh - #{$margin} * 2) * .8);
        max-height: calc((100vh - #{$nav-height} * 2) * .8);
        object-position: 50% 50%;
        object-fit: cover;
      }
    }
  }
}

.table-row_link .title {
  grid-column-start: 1;
  grid-column-end: 6;
  display: flex;
  @include type-30;
  & div#year {
    margin-right: 2.5rem;
    @include bp-xs {
      margin-right: 2.5rem;
    } 
  }
  @include bp-xs {
    grid-column-start: 1;
    grid-column-end: 13; 
    display: flex;
    padding: 2.75rem $margin 2.5rem $margin;
  }
}


.table-row_link .tags {
  grid-column-start: 6;
  grid-column-end: 13; 
  white-space: nowrap;
  @include type-18_caps;
  @include bp-xs {
    display: flex;
    align-items: center;
    @include type-12_caps;
    grid-column-start: 1;
    grid-column-end: 13; 
    border-top: solid black 1px;
    padding: calc(#{$margin} / 2.25) 0 calc(#{$margin} / 2) $margin;
  }
}

.mobile-dot {
  background: black;
  height: .5rem;
  width: .5rem;
  margin: 0 .75rem 0 .75rem;
  border-radius: 50% 50%;
  flex-shrink: 0;
}

.mobile-year {
  flex-shrink: 0;
}

.mobile-tags {
  flex-shrink: 0;
}

// ...
.slides-fade-enter {
  opacity: 0;
}

.slides-enter-active {
  // same speed delay to wait for fade out
  transition: opacity .2s linear;
}

.slides-fade-leave {
  opacity: 0;
}

// wait half a second before removing (transitioning out) 
// to give images a chance to load in bg
.slides-fade-leave-active {
  transition: opacity .2s linear .5s;
  z-index: 100;
  opacity: 0;
}


</style>