<template>
  <img :class='this.returnClass' @load="handleLoad" :data-src='this.url' :src='this.cached ? this.url : this.placeholder' />
</template>

<script>


export default {
  name: 'SlideImage',
  props: {
    url: String,
    placeholder: String, 
    cached: Boolean 
  },
  data() {
    return {
      loaded: false
    }
  },
  computed: {
    returnClass: function() {
      return this.cached || this.loaded ? 'loaded' : 'loading'
    }
  },
  methods: {
    handleLoad: function(event) {
      // would be better to do this another way...
      if (event.path[0].currentSrc === this.url) {
        this.loaded = true 
        if (!this.$store.state.cachedImages.includes(this.url)) {
          this.$store.dispatch('cacheImage', this.url)
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";
 

</style>
