import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Project from '../views/Project.vue'
import store from '../store/index'
import isTouchDevice from 'is-touch-device'


Vue.use(VueRouter)

// init 
function checkTouch() {
  if (isTouchDevice()) {
    store.dispatch('setTouch')
  }
}

function checkWindow(ww, wh) {
  if (ww <= 576) {
    store.dispatch('setMobile')
    store.dispatch('setWindowWidth', ww)
  }
  else if (ww > 576 && ww <= 875) {
    store.dispatch('setWindowWidth', ww)
  }
  else if (ww > 875 && ww <= 1290) {
    store.dispatch('setWindowWidth', ww)
  }
  else if (ww > 1290 && ww <= 1440) {
    store.dispatch('setWindowWidth', ww)
  }
  else if (ww > 1440 && ww < 1800) {
    store.dispatch('setWindowWidth', ww)
  }
  else {
    store.dispatch('setWindowWidth', ww)
  }
}


// global event listener for window resize
var resizeTimer
window.addEventListener('resize', (event) => {
  store.dispatch('setWindowResizing')
  clearTimeout(resizeTimer)
  resizeTimer = setTimeout(function() {
    store.dispatch('unsetWindowResizing')
    checkWindow(window.innerWidth, window.innerHeight)
  }, 250)
})

checkTouch()
checkWindow(window.innerWidth, window.innerHeight)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    children: [
      {
        path: "/projects/:project",
        name: "Project",
        component: () => import('../views/Project.vue'),
      },
    ],
    // todo: fix this (not working)
    beforeEnter: (to, from, next) => {
      // next()
      if (from.name === 'Project') {
        if (store.state.projectInfo) {
          store.dispatch('hideProjectInfo')
          setTimeout(() => {
            next()
            // ...ensure this is correct speed for transition of clip-path to finish
          }, 500)
        }
        else {
          next()
        }
      }
      else {
        next()
      }
    },
  },
  {
    path: '/info',
    name: 'Info',
    beforeEnter: (to, from, next) => {
      // console.log('>')
      // next()
      if (from.name === 'Project') {
        if (store.state.projectInfo) {
          store.dispatch('hideProjectInfo')
          setTimeout(() => {
            next()
            // ...ensure this is correct speed for transition of clip-path to finish
          }, 500)
        }
        else {
          next()
        }
      }
      else {
        next()
      }
    },
    component: () => import('../views/Info.vue'),
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

// router.beforeEach((to, from, next) => {
//   console.log('before')
// })

// router.afterEach((to, from) => {
//   // if (from.name === 'lecture' && to.name !== 'lectures' && to.name !== 'lecture') {
//   //    store.dispatch('resetOrgan')
//   // }
// })


// if (to.path !== "/login" && user == null) {
//   if (to.fullPath !== "/login/verify") {
//     next("/login");
//   }
//   else{ next(); } // --> HERE
// } else {
//   next();
// }
//  }

export default router
