<template>
  <div v-on:click='this.handleClick' v-if='this.files.length > 0' :class="returnClass" :style='this.returnStyle'>
    <VueSlickCarousel class='slide-wrap-inner' ref="carousel" v-bind="this.slickSettings">
      <figure ref='slide' class = 'slide-wrap-inner_slide' v-for='file in this.files' :key='file.id' :data-type='file.type'>
        <SlideImage :cached='returnCachedStatus(file)' v-if='file.type === "image"' :placeholder='returnImgPlaceholder(file)' :url='returnImgSrc(file)' />
        <SlideVideo v-else :url='file.file' />
      </figure>
    </VueSlickCarousel>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import Vue from 'vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import VueLazyload from 'vue-lazyload'
import SlideImage from './SlideImage.vue'
import SlideVideo from './SlideVideo.vue'

Vue.use(VueLazyload, {
  lazyImage: true,
  lazyComponent: true
})

export default {
  name: 'Slides',
  props: {
    mobile: Boolean,
    touch: Boolean,
    windowWidth: Number,
    files: Array,
    slideKey: String,
    projectInfo: Boolean,
    showSlides: Boolean,
    slideTotal: Number,
    slideCount: Number,
    clipWidth: Number,
    variableClipWidth: Boolean,
    cachedImages: Array
  },
  components: {
    VueSlickCarousel,
    SlideImage,
    SlideVideo
  },
  data() {
    return {
      slickSettings: {
        "autoplay": false,
        "dots": false,
        "arrows": false,
        "fade": false,
        "infinite": true,
        "speed": 750,
        "centerMode": false,
        "variableWidth": true,
        "slidesToShow": 3,
        "slidesToScroll": 1,
        "touchMove": false,
        "swipe": true,
        "draggable": false,
      },
      transitionSpeed: 500
    }
  },
  mounted() {
    // timeout to wait for slides to load 
    const self = this 
    // setTimeout(() => {
    //   self.bindObserver()
    // }, 3000)
    // this.currentSlides = this.files 
    this.$route.name === 'Index' ? this.lowRes = true : this.lowRes = false 
    // const self = this 
    // this.$Lazyload.$on('loaded', function ({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error }, formCache) {
    //   self.handleLazyLoad(src)
    // })
  },
  watch: {
    '$route': function() {
      console.log('route updated in slides')
    }
  },
  computed: {
    returnKey: function() {
      return !this.imagesLoaded ? 'slides' : 'slides'
    },
    returnClass: function() {
      if (this.$route.name === "Project") {
        return this.touch || this.mobile ? 'slide-wrap transition touch' + ' ' + this.$route.name.toLowerCase() : 'slide-wrap transition' + ' ' + this.$route.name.toLowerCase()
      }
      else {
        if (!this.variableClipWidth) {
          if (this.touch || this.mobile) {
            return this.showSlides ? 'slide-wrap transition touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap transition touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
          else {
            return this.showSlides ? 'slide-wrap transition' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap transition' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
        }
        else {
          if (this.touch || this.mobile) {
            return this.showSlides ? 'slide-wrap touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap touch' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
          else {
            return this.showSlides ? 'slide-wrap' + ' ' + this.$route.name.toLowerCase() + ' ' + 'visible' : 'slide-wrap' + ' ' + this.$route.name.toLowerCase() + ' ' + 'hidden'
          }
        }
      }
    },
    returnStyle: function() {
      if (this.$route.name === 'Project') {
        if (this.projectInfo) {
          return this.windowWidth <= 875 ? 'clip-path: inset(0 50vw 0 0 round 0px);' : 'clip-path: inset(0 25vw 0 0 round 0px);' 
        }
        else {
          return 'clip-path: inset(0 0 0 0vw round 0px);'
        }
      }
      else {
        if (!this.touch && !this.mobile) {
          if (this.variableClipWidth) {
            return "clip-path: inset(0 0 0" + " " + this.clipWidth + "px round 0px);"
          }
          else {
            return 'clip-path: inset(0 0 0 50vw round 0px);'
          }
        }
        else {
          return 'clip-path: inset(0 0 0 100vw round 0px);'
        }
      }
    }
  },
  methods: {
    returnCachedStatus: function(f) {
      return this.cachedImages.includes(f.files.thumbs.md) ? true : false
    },
    returnImgPlaceholder: function(f) {
      return f.files.thumbs.xxs
    },
    returnImgSrc: function(f) {
      return f.extension === 'gif' ? f.original : f.files.thumbs.md
    },
    // handleBeforeChange: function(prevIndex, newIndex) {
    //   this.$store.dispatch('setSlideCount', newIndex + 1)
    // },
    handleNext() {
      // const inner = document.querySelector('.slide-wrap-inner')
      // inner.scrollBy(100, 0)
      // this.currentSlide += 1
      // const self = this 
      // setTimeout(() => {
      //   self.currentSlides.push(self.files[self.currentSlide])
      // }, 750)
      // this will be something like (currentSlideWidth / sliderWidth) * 100
      // let transformPercent = 50 
      // inner.style.transform = 'translate(' + -25 + '%' + ',' + 0 + '%' + ')'
      this.$refs.carousel.next()
    },
    handlePrev() {
      // console.log('handle prev')
      this.$refs.carousel.prev()
    },
    handleClick: function(event) {
      event.pageX > (window.innerWidth / 2) ? this.handleNext() : this.handlePrev()
    }
  }
}

</script>

<style lang="scss">

@import "../styles/_helpers.scss";

.slide-wrap {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 1;
  pointer-events: none;
  margin-top: $nav-height;
  height: calc(100% - #{$nav-height});
  width: 100%;
  overflow: hidden;
  z-index: 2;
  display: flex;
  &.touch {
    height: calc(100% - (#{$nav-height} + #{$footer-height}));
  }
  &.transition {
    transition: .5s ease;
  }
  &.project {
    pointer-events: all;
  }
  &-inner {
    transition: transform .25s linear;
    background: black;
    overflow-x: scroll;
    width: 100%;
    height: 100%;
    display: flex;
    // ...temp?
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &_slide {
      position: relative;
      height: auto !important;
      width: auto !important;
      & img {
        transition: filter .25s linear;
        &.loading {
          filter: blur(1px);
        }
        &.loaded {
          filter: blur(0);
        }
        // ...
        object-fit: cover;
        max-width: 100vw !important;
        width: auto !important;
        height: calc(100vh - #{$nav-height}) !important;
      }
      & video {
        height: calc(100vh - #{$nav-height}) !important;
        width: auto;
        object-fit: cover;
        max-width: 100vw !important;
      }
    }
  }
}

.slides-fade-enter {
  opacity: 0;
}

.slides-enter-active {
  // same speed delay to wait for fade out
  transition: opacity .2s linear;
}

.slides-fade-leave {
  opacity: 0;
}

.slides-fade-leave-active {
  transition: opacity .2s linear .75s;
  z-index: 100;
  opacity: 0;
}

// .slide-wrap-carousel {
//   width: 100%;
//   height: 100%;
//   display: flex;
// }


// .slide-wrap-carousel_slide {
//   &.portrait {
//     position: relative;
//     height: auto !important;
//     width: auto !important;
//     & img {
//       width: 50vw !important;
//       height: calc(100vh - #{$nav-height}) !important;
//       object-fit: cover;
//       display: block !important;
//     }
//   }
//   &.touch.portrait img {
//     height: calc(100vh - (#{$nav-height} + #{$footer-height})) !important;
//   }
//   &.landscape {
//     position: relative;
//     height: auto !important;
//     width: auto !important;
//     & img {
//       width: 100vw !important;
//       height: calc(100vh - #{$nav-height}) !important;
//       object-fit: cover;
//       display: block !important;
//     }
//   }
//   &.touch.landscape img {
//     height: calc(100vh - (#{$nav-height} + #{$footer-height})) !important;
//   }
//   &.auto {
//     position: relative;
//     height: auto !important;
//     width: auto !important;
//     & img {
//       object-fit: cover;
//       max-width: 100vw !important;
//       width: auto !important;
//       height: calc(100vh - #{$nav-height}) !important;
//     }
//     & video {
//       height: calc(100vh - #{$nav-height}) !important;
//       width: auto;
//       object-fit: cover;
//       max-width: 100vw !important;
//     }
//   }
//   &.touch.auto img {
//     height: calc(100vh - (#{$nav-height} + #{$footer-height})) !important;
//   }
// }


</style>
