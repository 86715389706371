<template>
  <!-- <div> -->
  <!-- <lazy-component> -->
    <video :src='this.url' poster='null' muted playsinline disableRemotePlayback loop></video>
  <!-- </lazy-component> -->
  <!-- </div> -->
</template>

<script>


export default {
  name: 'SlideVideo',
  props: {
    url: String
  },
  data() {
    return {
      loaded: false 
    }
  },
  computed: {
    returnClass: function() {
      return this.loaded ? 'loaded' : 'loading'
    }
  },
  methods: {
    handleLoad: function() {
      this.loaded = true 
      // if (!this.$store.state.cachedImages.includes(this.url)) {
      //   this.$store.dispatch('cacheImage', this.url)
      // }
    }
  }
}

</script>

<style scoped lang="scss">

@import "../styles/_helpers.scss";

// styles for img are taken care of in Slides.vue
 

</style>
