<template>
  <div id="app" v-on:mousemove='handleMouseMove'>
    <Header 
      :mobile='this.mobile' 
      :touch='this.touch' 
      :windowWidth='this.windowWidth' 
      :title='this.title' 
      :slideCount='this.slideCount' 
      :slideTotal='this.slideTotal' 
      :filter='this.filter' 
      :projectInfo='this.projectInfo' 
    />
    <transition 
      :name='this.returnTransition'
      v-on:before-enter="beforeEnter"
      v-on:after-enter="afterEnter"
      v-on:before-leave="beforeLeave"
      v-on:after-leave="afterLeave"
    >
      <router-view 
        :key='this.returnKey' 
        :class='this.returnViewClass' 
        :mobile='this.mobile' 
        :touch='this.touch' 
        :indexData='this.indexData'
        :indexProject='this.indexProject'
        :windowWidth='this.windowWidth'
        :title='this.title' 
        :textCursor='this.textCursor' 
        :clipWidth='this.clipWidth' 
        :cursorPos='this.cursorPos' 
        :slideCount='this.slideCount' 
        :slideTotal='this.slideTotal' 
        :showSlides='this.showSlides' 
        :filter='this.filter' 
        :projectInfo='this.projectInfo' 
        :cachedImages='this.cachedImages' 
        :imageView='this.imageView'
      />
    </transition>
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import CustomCursor from './components/CustomCursor.vue'
import ress from 'ress'
import api from './utils/api'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    CustomCursor
  },
  data() {
    return {
      clipWidth: 0,
      cursorPos: {
        x: -1000,
        y: -1000
      },
      textCursor: false,
    }
  },
  computed: {
    ...mapState({
      mobile: 'mobile',
      touch: 'touch',
      indexData: 'indexData',
      indexProject: 'indexProject',
      windowWidth: 'windowWidth',
      title: 'title',
      filter: 'filter',
      projectInfo: 'projectInfo',
      showSlides: 'showSlides',
      slideCount: 'slideCount',
      slideTotal: 'slideTotal',
      cachedImages: 'cachedImages',
      cursor: 'cursor',
      imageView: 'imageView'
    }),
    returnViewClass: function() {
      if (this.$route.name !== null) {
        return this.touch ? 'view touch' + ' ' + this.$route.name.toLowerCase() : 'view' + ' ' + this.$route.name.toLowerCase()
      }
    },
    returnKey: function() {
      return this.$route.name === "Project" || this.$route.path === '/' ? 'index' : this.$route.name
    },
    returnTransition: function() {
      if (this.touch || this.mobile) {
        return this.$route.name === 'Index' ? 'slide-down' : 'slide-up'
      }
      else {
        return this.$route.name === 'Index' ? 'slide-left' : 'slide-right'
      }
    }
  },
  mounted() {
    const self = this 
    if (Object.keys(this.indexData).length <= 0) {
      this.getIndexData()
    }
    if (this.mobile) {
      this.$store.dispatch('setImageView')
    }
  },
  methods: {
    getIndexData: function() {
      const self = this 
      api.getPage('home').then((response) => {
        self.$store.dispatch('setIndexData', response.data.projects)
        self.$store.dispatch('setIndexProject', response.data.projects[0])
      }).catch(error => {
        // return 404 or smtng here
        console.log(error)
      })
    },
    handleMouseMove: function(event) {
     if (this.$route.name === "Project") {
      this.cursorPos = {
        x: event.pageX,
        y: event.pageY
      }
     }

     if (event.pageX <= (window.innerWidth / 2)) {
       if (!this.mobile) {
         this.textCursor = false
       }
        this.clipWidth = window.innerWidth / 2
      }
      else {
       if (!this.mobile) {
         this.textCursor = true
       }
        this.clipWidth = window.innerWidth - parseInt(event.pageX)
      }
    },
    beforeEnter: function() {
      // ...
      // console.log('before enter', this.$route.name)
    },
    afterEnter: function() {
      if (this.$route.name === 'Info') {
        this.$store.dispatch('resetFilter')
      }
    },
    beforeLeave: function() {
      // ...
      // console.log('before leave', this.$route.name)
    },
    afterLeave: function() {
      // ...
      // console.log('after leave', this.$route.name)
    }
  }
}

</script>

<style lang="scss">

@import "./styles/_helpers.scss";

html,body {
  width: 100%;
  height: 100%;
  font-size: 9.5px;
  font-family: AkzidenzRegular;
  // responsive font sizes not definitive
  @include bp-xs {
    font-size: 8px;
  }
  @include bp-md {
    font-size: 9.5px;
  }
  @include bp-lg {
    font-size: 10px;
  }
  @include bp-xl {
    font-size: 10.5px;
  }
  @include bp-xxl {
    font-size: 11.5px;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  // lock page
  position: fixed;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

* > a {
  text-decoration: none;
  color: inherit;
  // cursor: none !important;
}

.view {
  @include grid;
  height: calc(100% - #{$nav-height});
  min-height: calc(100% - #{$nav-height});
  position: fixed;
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  background: white;
  top: 0;
  margin-top: $nav-height;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.touch.index {
    padding-bottom: calc(#{$nav-height} - 1px);
  }
  &.index {
    @include bp-xs {
      // 1px is for border
      padding-bottom: calc(#{$nav-height} - 1px);
    }
  }
}


.view {
  cursor: url('/images/dot-white.svg'), auto !important;
  & a {
    cursor: url('/images/dot-white.svg'), auto !important;
  }
  &.project {
    cursor: none !important;
  }
  &.info {
    cursor: url('/images/dot-black.svg'), auto !important;
  }
}

* > p {
  margin-bottom: 1em;
}



// --- clip slide (desktop) ---
// ... left ...
.slide-left-enter {
  // transition: all .5s linear;
}

.slide-left-enter-active {
  // transition: all .5s linear;
}

.slide-left-leave {
  z-index: 100;
  transition: all .5s ease-out .6s;
  clip-path: inset(0 0 0 0 round 0px);
}

.slide-left-leave-active {
  transition: all .5s ease-out .6s;
  z-index: 100;
  clip-path: inset(0 0 0 0 round 0px);
}

.slide-left-leave-to {
  z-index: 100;
  transition: all .5s ease-out .6s;
  clip-path: inset(0 100vw 0 0 round 0px);
}

// ... right ...
.slide-right-enter {
  // transition: all .5s linear;
}

.slide-right-enter-active {
  // transition: all .5s linear;
}

.slide-right-leave {
  z-index: 100;
  transition: all .5s ease-out .6s;
  clip-path: inset(0 0 0 0 round 0px);
}

.slide-right-leave-active {
  z-index: 100;
  transition: all .5s ease-out .6s;
  clip-path: inset(0 0 0 0 round 0px);
}

.slide-right-leave-to {
  z-index: 100;
  transition: all .5s ease-out .6s;
  clip-path: inset(0 0 0 100vw round 0px);
}


// --- clip slide (mobile) ---
// ... up ...
.slide-up-enter-active {
  transition: all .5s ease-out .5s;
  clip-path: inset(0 0 0 0 round 0px);
}
.slide-up-leave-active {
  transition: all .5s ease-out 1s;
  clip-path: inset(0 0 0 0 round 0px);
}
.slide-up-enter, .slide-up-leave-to {
  clip-path: inset(100vh 0 0 0 round 0px);
}

// ... down ... 
.slide-down-enter-active {
  transition: all .5s ease-out .5s;
  clip-path: inset(0 0 0 0 round 0px);
}
.slide-down-leave-active {
  transition: all .5s ease-out 1s;
  clip-path: inset(0 0 0 0 round 0px);
}
.slide-down-enter, .slide-down-leave-to {
  clip-path: inset(0 0 100vh 0 round 0px);
}


</style>
