<template>
    <div :class="returnViewClass">
      <div class = 'project-images' :style='this.returnStyle' v-if='Object.keys(this.data).length > 0 && this.mobile || Object.keys(this.data).length > 0 && this.touch'>
        <figure class = 'project-images-figure lazyload' v-for='file in this.returnFiles' :key='file.id'>
          <div ref='waypoint' class='project-images-figure_waypoint' :data-index='file.index'></div>
          <img class="project-images-figure_img lazyload" data-sizes="100vw" :data-src='file.files.thumbs["sm"]' :data-srcset="returnSrcSet(file.files.thumbs)" />
        </figure>
      </div>
      <CustomCursor v-if='!this.touch && !this.mobile' :cursorPos='this.cursorPos' />
      <transition name = 'panel'>
        <div v-if='this.projectInfo' :class='this.returnPanelClass' v-on:mouseenter='handleMouseEnter' v-on:mouseleave='handleMouseLeave'>
          <div class = 'info-panel_header'>
            <h1 class = 'title'>{{ this.data.title }}</h1> 
            <h2 class = 'year'><span v-for='i in returnKernedYear(this.data.year)' :key='i' :class='returnLetterClass(i)'>{{ i }}</span></h2>
          </div>
          <div class = 'info-panel_description' v-html='this.data.description'></div>
          <div class = 'info-panel_tags'>
            {{ this.data.tags }}
          </div>
        </div>
      </transition>
    </div>
</template>

<script>
import api from '../utils/api'
import CustomCursor from '../components/CustomCursor.vue'
import debounce from 'lodash/debounce'
import 'lazysizes'
import 'lazysizes/plugins/blur-up/ls.blur-up'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
lazySizes.cfg.expand = 0


export default {

  name: 'Project',
  data() {
    return {
      data: {},
      observerBound: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px -60% 0px',
        threshold: 0
      }
    }
  },
  props: {
    touch: Boolean,
    mobile: Boolean,
    projectInfo: Boolean,
    cursorPos: Object
  },
  components: {
    CustomCursor
  },
  beforeDestroy() {
    this.$store.dispatch('hideProjectInfo')
    this.$store.dispatch('unsetTitle')
    this.$store.dispatch('unsetSlideTotal')
    this.$store.dispatch('resetSlideCount')
  },
  updated: debounce(function () {
    this.$nextTick(() => {
      this.bindObserver()
    })
  }, 250),
  mounted() {
    // on mount, populate state with data 
    this.getData()
    // seems to work: keep an eye on this + test in other browsers
    this.bindObserver()
  },
  watch: {
    'data': function(newVal, oldVal) {
      const self = this
      this.$store.dispatch('setTitle', this.data.title)
      this.$store.dispatch('hideProjectInfo')
      this.observerBound = false 
      setTimeout(() => {
        self.bindObserver()
      }, 100)
    },
  },
  computed: {
    returnFiles: function() {
      console.log('files', this.data.files)
      return this.data.files
    },
    returnViewClass: function() {
      return this.touch ? 'project-view touch' : this.mobile ? 'project-view mobile' : 'project-view' 
    },
    returnPanelClass: function() {
      return this.touch || this.mobile ? 'info-panel touch' : 'info-panel'
    },
    // for mobile/touch only 
    returnStyle: function() {
      if (this.projectInfo) {
        // currently clipping width instead of height for project info transition only
        // would need to make project images fixed 100 height + overflow scroll 
        // if vertical clip transition would be used
        return 'clip-path: inset(0 0 0 100vw round 0px); pointer-events: none'
      }
      else {
        return 'clip-path: inset(0 0 0 0 round 0px); pointer-events: all'
      }
    }
  },
  methods: {
    returnLetterClass: function(n) {
      return 'n-' + n
    },
    returnKernedYear: function(year) {
      let arr = []

      for (let i = 0; i < year.length; i++) {
        arr.push(year[i])
      }
      return arr 
    },
    bindObserver: function() {
      const self = this 
      const observer = new IntersectionObserver(this.observerCallback, this.intersectionOptions)
      let target = this.$refs.waypoint
      if (target) {
        if (this.mobile && !this.observerBound || this.touch && !this.observerBound) {
          console.log('now bind observer')
          target.forEach((i) => {
            if (i) {
              observer.observe(i)
            }
          })
          this.observerBound = true
        }
      }
    },
    observerCallback: function(entries, observer) {
      const self = this 
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.getAttribute('data-index'))
          this.$store.dispatch('setSlideCount', index)
        }
      })
    },
    returnSrcSet: function(thumbs) {
      return `${thumbs['xxs']} 100w, ${thumbs['xs']} 300w, ${thumbs['sm']} 600w, ${thumbs['md']} 1200w, ${thumbs['lg']} 1600w, ${thumbs['xl']} 2000w`
    },
    getData: function() {
      const self = this
      api.getPage(self.$route.params.project).then((response) => {
        self.data = response.data
        self.$store.dispatch('setSlideTotal', response.data.files.length)
      }).catch(error => {
        console.log(error)
      })
    },
    handleMouseEnter: function() {
      this.$store.dispatch('setShapeCursor', 'dot')
    },
    handleMouseLeave: function() {
      this.$store.dispatch('unsetShapeCursor')
    }
  }
}
</script>

<style lang="scss">

@import "../styles/_helpers.scss";

.project-view {
  &.touch {
    background: black; 
    position: fixed;
    top: 0;
    margin-top: $nav-height;
    // this height is contingent on footer height being same as header height
    // --- may need to be updated
    height: calc(100% - (#{$nav-height} * 2));
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &.mobile {
    background: black; 
    position: fixed;
    top: 0;
    margin-top: $nav-height;
    // this height is contingent on footer height being same as header height
    // --- may need to be updated
    height: calc(100% - (#{$nav-height} * 2));
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

// touch/mobile 
.project-images {
  width: 100%;
  display: flex;
  flex-flow: column;
  position: relative; 
  z-index: 101;
  font-size: 0;
  transition: .5s ease;
  &-figure {
    position: relative;
    width: 100%;
    & img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    &_waypoint {
      height: 10px;
      top: 0;
      position: absolute;
      width: 100%;
      z-index: 101;
      pointer-events: none;
      opacity: 0;
    }
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity .25s linear;
}

.info-panel {
  height: calc(100vh - #{$nav-height});
  position: fixed;
  margin-top: $nav-height;
  top: 0;
  right: 0;
  width: 25vw;
  background: black;
  color: white;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.touch {
    z-index: 10;
    height: calc(100vh - (#{$nav-height} + #{$footer-height}));
  }
  @include bp-sm {
    width: 50vw;
  }
  @include bp-xs {
    z-index: 10;
    width: 100vw;
  }
  &_header {
    border-bottom: solid white 1px;
    padding: 1.6rem;
    position: sticky;
    top: 0;
    background: black;
    & h1, h2, h3 {
      font-weight: normal;
    }
    & h1, h2 {
      @include type-24;
    }
    & h3 {
      @include type-18;
    }
  }
  &_description {
    @include type-16_body;
    padding: 1.6rem 1.6rem 5rem 1.6rem;
    @include bp-xs {
      padding: 1.6rem 1.6rem 10rem 1.6rem;
    }
    & p {
      margin-bottom: 1em;
    }
  }
  &_tags {
    width: 25vw;
    padding: 1.6rem;
    z-index: 2;  
    background: black;
    position: fixed;
    bottom: 0;
    border-top: solid white 1px;
    @include type-12_caps;
    @include bp-xs {
      bottom: $nav-height;
    }
    @include bp-sm {
      width: 50vw;
    }
    @include bp-xs {
      width: 100vw;
    }
  }
}

.info-panel.touch .info-panel_description {
  // 10rem is optical
  padding: 1.6rem 1.6rem 10rem 1.6rem;
}

.info-panel.touch .info-panel_tags {
  bottom: $nav-height;
}

// --- transitions ---
// -- this prevents project info from showing beneath slide on transition

// --- desktop/tablet --- 
.panel-enter {
  opacity: 0;
}

.panel-enter-active {
  // same speed delay to wait for fade out
  transition: opacity 0 linear 0;
}

.panel-leave {
  opacity: 0;
}

.panel-leave-active {
  transition: opacity 1s linear 2s;
  opacity: 0;
}

</style>
